@tailwind components;
@tailwind utilities;

.skeleton {
  @apply relative overflow-hidden bg-gray-400 cursor-wait opacity-20;
}

.skeleton-circle {
  @apply skeleton;
  @apply rounded-full;
}

.skeleton > *,
.skeleton-circle > * {
  opacity: 0;
}

.skeleton::after,
.skeleton-circle::after {
  @apply absolute top-0 bottom-0 left-0 right-0;
  content: ' ';
}

.skeleton.shimmer::after,
.skeleton-circle.shimmer::after {
  @apply animate-slide-infinite bg-skeleton-gradient;
}

/* taboola sidebar promo widgets */

.sidebar .trc_rbox_container {
  @apply bg-white px-[18px] pb-[6px];
}

.sidebar .trc_rbox_container {
  box-shadow: 0 0 3px 0 rgba(51, 51, 51, 0.18);
}

.ams-sticky #htlad-12-gpt {
  position: relative !important;
}
